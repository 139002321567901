import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {ModuleMenuModel as ModuleMenu} from './module-menu.model';

import {TranslateService} from '../translate/translate.service';

import {HeaderService} from '../../layout/header/header.service';
import {LeftSidebarService} from '../../layout/left-sidebar/left-sidebar.service';
import {BreadcrumbService} from '../../layout/breadcrumb/breadcrumb.service';
import {AuthService} from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleMenuService {

  private module: ModuleMenu;
  private modules: ModuleMenu[];

  constructor(
    private translateService: TranslateService,
    private headerService: HeaderService,
    private leftSidebarService: LeftSidebarService,
    private breadcrumbService: BreadcrumbService,
    public authService: AuthService
  ) {
    this.initMenu();
    this.headerService.setModules(this.modules);
    this.leftSidebarService.setModule(this.modules[0]);
  }

  initMenu() {
    this.modules = [
      new ModuleMenu('Home', '/Home/dashboard', 'home', 'Home', [
        new ModuleMenu('', '/Home/dashboard', 'home', 'Dashboard'),
        new ModuleMenu('', '/Sales/dashboard', 'shopping_cart', 'Sales'),
        new ModuleMenu('', '/Purchases/dashboard', 'add_shopping_cart', 'Purchases'),
        new ModuleMenu('', '/Logistics/dashboard', 'dns', 'Logistics'),
        new ModuleMenu('', '/Manufacturing/dashboard', 'settings', 'Manufacturing'),
        new ModuleMenu('', '/Financial/dashboard', 'attach_money', 'Financial'),
        new ModuleMenu('', '/Home/report', 'trending_up', 'Report', null, this.authService.hasPrivilege('SalesReportSalesDCHN', 'SalesReportSalesDCHN1', 'VIEW')),
        new ModuleMenu('', '', 'assessment', 'Biểu đồ', [
          new ModuleMenu('', '/Home/chart/payment-income', 'grain', 'Doanh số', null, this.authService.hasPrivilege('PaymentIncomeChart', 'PaymentIncomeChart1', 'VIEW')),
          new ModuleMenu('', '/Home/chart/sales-revenue', 'grain', 'Doanh thu', null, this.authService.hasPrivilege('SalesRevenueChart', 'SalesRevenueChart1', 'VIEW')),
          new ModuleMenu('', '/Home/chart/cus-ending', 'grain', 'Công nợ', null, this.authService.hasPrivilege('CusEndingBalanceChart', 'CusEndingBalanceChart1', 'VIEW')),
          new ModuleMenu('', '/Home/chart/cat-revenue', 'grain', 'Tỷ trọng doanh số', null, this.authService.hasPrivilege('CatRevenueChart', 'CatRevenueChart1', 'VIEW')),
        ]),
        new ModuleMenu('', '/Admin/dashboard', 'build', 'Admin'),
      ]),

      // ------------------- //

      new ModuleMenu('Sales', '/Sales/dashboard', 'shopping_cart', 'Sales', [

        new ModuleMenu('', '/Sales/dashboard', 'home', 'Dashboard', null, (this.authService.hasPrivilege('SalesDashboard', 'SalesDashboard1', 'VIEW') || this.authService.hasPrivilege('SalesDashboard', 'SalesDashboard2', 'VIEW'))),

        new ModuleMenu('', '', 'assignment', 'SalesOrders', [
          new ModuleMenu('', '/Sales/orders-create', 'add_circle', 'createNew', null, this.authService.hasPrivilege('SalesCreateOrder', 'SalesCreateOrder1', 'CREATE')),
          new ModuleMenu('', '/Sales/orders-list', 'list', 'list', null, this.authService.hasPrivilege('SalesListOrder', 'SalesListOrder1', 'VIEW')),
          new ModuleMenu('', '/Sales/price-modified', 'list', 'Giá bán khác CSG', null, this.authService.hasPrivilege('SalePriceModified', 'SalePriceModified1', 'VIEW')),
        ]),

        new ModuleMenu('', '', 'assignment', 'Phiếu giao hàng', [
          new ModuleMenu('', '/Sales/shipments-create-single', 'event', 'Tạo 1 phiếu', null, this.authService.hasPrivilege('SalesOrderRequireShipment', 'SalesOrderRequireShipment1', 'VIEW')),
          new ModuleMenu('', '/Sales/shipments-create-batch', 'event', 'Tạo hàng loạt', null, this.authService.hasPrivilege('SalesOrderRequireShipmentBatch', 'SalesOrderRequireShipmentBatch1', 'VIEW')),
          new ModuleMenu('', '/Sales/shipments-list', 'receipt', 'Phiếu đã tạo', null, this.authService.hasPrivilege('SalesOrderShipmentList', 'SalesOrderShipment1', 'VIEW'))
        ]),

        new ModuleMenu('', '', 'check_circle', 'Sửa giá và CK', [
          new ModuleMenu('', '/Sales/order-item-price-appr', 'list', 'Duyệt sửa', null, true),
          new ModuleMenu('', '/Sales/order-item-price-modify', 'list', 'Danh sách', null, true),
        ]),

        new ModuleMenu('', '/Sales/report-do', 'book', 'Lịch giao hàng', null, this.authService.hasPrivilege('SalesListOrderByProduct', 'SalesListOrderByProduct1', 'VIEW')),
        new ModuleMenu('', '/Sales/shipment-payments', 'book', 'Thu theo DO', null, this.authService.hasPrivilege('SalesListOrderByProduct', 'SalesListOrderByProduct1', 'VIEW')),

        new ModuleMenu('', '/Sales/liability-estimate', 'book', 'Kế hoạch công nợ', null, this.authService.hasPrivilege('SalesLiabilityEstimate', 'SalesLiabilityEstimate1', 'VIEW')),

        new ModuleMenu('', '', 'assignment', 'Phiếu trả hàng', [
          new ModuleMenu('', '/Sales/return-orders-create', 'add_circle', 'createNew', null, this.authService.hasPrivilege('ReturnOrderCreate', 'ReturnOrderCreate1', 'CREATE')),
          new ModuleMenu('', '/Sales/return-orders-list', 'list', 'list', null, this.authService.hasPrivilege('ReturnOrderList', 'ReturnOrderList1', 'VIEW')),
        ]),

        /*new ModuleMenu('', '', 'assignment', 'Xuất bán từ KTC', [
          new ModuleMenu('', '', 'assignment', 'SalesOrders', [
            new ModuleMenu('', '/Sales/Exhibit-Sales/orders/create', 'add_circle', 'createNew', null, this.authService.hasPrivilege('SalesCreateOrder', 'SalesCreateOrder1', 'CREATE')),
            new ModuleMenu('', '/Sales/Exhibit-Sales/orders/list', 'list', 'list', null, this.authService.hasPrivilege('SalesListOrder', 'SalesListOrder1', 'VIEW')),
            new ModuleMenu('', '/Sales/Exhibit-Sales/orders/price-modified', 'list', 'Giá bán khác CSG', null, this.authService.hasPrivilege('SalePriceModified', 'SalePriceModified1', 'VIEW')),
          ]),

          new ModuleMenu('', '', 'assignment', 'Phiếu giao hàng', [
            new ModuleMenu('', '/Sales/Exhibit-Sales/shipments-create-single', 'event', 'Tạo 1 phiếu', null, this.authService.hasPrivilege('SalesOrderRequireShipment', 'SalesOrderRequireShipment1', 'VIEW')),
            new ModuleMenu('', '/Sales/Exhibit-Sales/shipments-list', 'receipt', 'Phiếu đã tạo', null, this.authService.hasPrivilege('SalesOrderShipmentList', 'SalesOrderShipment1', 'VIEW'))
          ]),

          new ModuleMenu('', '/Sales/Exhibit-Sales/report-do', 'book', 'Lịch giao hàng', null, this.authService.hasPrivilege('SalesListOrderByProduct', 'SalesListOrderByProduct1', 'VIEW')),
          new ModuleMenu('', '/Sales/Exhibit-Sales/shipment-payments', 'book', 'Thu theo DO', null, this.authService.hasPrivilege('SalesListOrderByProduct', 'SalesListOrderByProduct1', 'VIEW')),

        ]),*/

        new ModuleMenu('', '/Sales/customers', 'account_box', 'Customers', null, this.authService.hasPrivilege('SalesCustomerList', 'SalesCustomerList1', 'VIEW')),
        new ModuleMenu('', '/Sales/product-price', 'book', 'Chính sách giá', null, this.authService.hasPrivilege('SalesProductPriceAgrt', 'SalesProductPriceAgrt1', 'VIEW')),
        // new ModuleMenu('', '/Sales/categories', 'view_agenda', 'Danh mục'),

        new ModuleMenu('', '', 'gavel', 'Warranty', [
          new ModuleMenu('', '/Sales/warranties-create', 'add_circle', 'createNew', null, this.authService.hasPrivilege('CustomerWarrantyCreate', 'CustomerWarrantyCreate1', 'CREATE')),
          new ModuleMenu('', '/Sales/warranties-list', 'list', 'list', null, this.authService.hasPrivilege('CustomerWarrantyList', 'CustomerWarrantyList1', 'VIEW')),
        ]),

        new ModuleMenu('', '/Sales/products', 'book', 'Products', null, this.authService.hasPrivilege('SaleProductList', 'SaleProductList1', 'VIEW')),
        new ModuleMenu('', '/Sales/profit-estimate', 'attach_money', 'Hạch toán giá bán', null, this.authService.hasPrivilege('SaleProfitEst', 'SaleProfitEst1', 'VIEW')),
      ]),

      // ------------------- //

      new ModuleMenu('Purchases', '/Purchases/dashboard', 'add_shopping_cart', 'Purchases', [

        new ModuleMenu('', '/Purchases/dashboard', 'home', 'Dashboard', null, (this.authService.hasPrivilege('PurchaseDashboard', 'PurchaseDashboard1', 'VIEW') || this.authService.hasPrivilege('PurchaseDashboard', 'PurchaseDashboard2', 'VIEW'))),
        new ModuleMenu('', '/Purchases/order-plan-dchn', 'compare', 'Kế hoạch MH DCHN', null, this.authService.hasPrivilege('PurchaseOrderPlanDchn', 'PurchaseOrderPlanDchn1', 'VIEW')),
        new ModuleMenu('', '/Purchases/order-plan-fsn', 'compare', 'Kế hoạch MH FSN', null, this.authService.hasPrivilege('PurchaseOrderPlanFsn', 'PurchaseOrderPlanFsn1', 'VIEW')),
        new ModuleMenu('', '/Purchases/create-purchasing-order', 'add_circle', 'Tạo mới đơn hàng', null, this.authService.hasPrivilege('PurchaseCreateOrder', 'PurchaseCreateOrder1', 'CREATE')),
        new ModuleMenu('', '/Purchases/purchasing-order', 'list', 'Danh sách đơn hàng', null, this.authService.hasPrivilege('PurchaseListOrder', 'PurchaseListOrder1', 'VIEW')),
        new ModuleMenu('', '', 'check_circle', 'Sửa giá mua', [
          new ModuleMenu('', '/Purchases/order-item-price-appr', 'list', 'Duyệt sửa', null, true),
          new ModuleMenu('', '/Purchases/order-item-price-modify', 'list', 'Danh sách', null, true),
        ]),
        new ModuleMenu('', '/Purchases/order-product-dim-view', 'book', 'Lịch nhập hàng', null, this.authService.hasPrivilege('PurchaseListOrderByProduct', 'PurchaseListOrderByProduct1', 'VIEW')),
        new ModuleMenu('', '/Purchases/price-modified', 'list', 'Đơn hàng khác CSG', null, this.authService.hasPrivilege('PurchaseOrderPriceModified', 'PurchaseOrderPriceModified1', 'VIEW')),
        new ModuleMenu('', '/Purchases/returns', 'call_missed', 'Phiếu trả hàng', null),
        new ModuleMenu('', '/Purchases/providers', 'person_pin', 'Provider', null, this.authService.hasPrivilege('PurchaseSupplier', 'PurchaseSupplier1', 'VIEW')),
        new ModuleMenu('', '/Purchases/product-price', 'book', 'Chính sách giá', null, this.authService.hasPrivilege('PurchaseSupplierPrices', 'PurchaseSupplierPrices1', 'VIEW')),
      ]),

      // ------------------- //

      new ModuleMenu('Logistics', '/Logistics/dashboard', 'dns', 'Logistics', [

        new ModuleMenu('', '/Logistics/import', 'format_indent_increase', 'Phiếu nhập', null, this.authService.hasPrivilege('IncomingShipment', 'IncomingShipment1', 'VIEW')),
        new ModuleMenu('', '/Logistics/export', 'format_indent_decrease', 'Phiếu xuất', null, this.authService.hasPrivilege('OutgoingShipment', 'OutgoingShipment1', 'VIEW')),

        new ModuleMenu('', '', 'move_to_inbox', 'Nhập thành phẩm', [
          new ModuleMenu('', '/Logistics/create-receive-direct', 'add_circle', 'createNew', null, this.authService.hasPrivilege('AssetReceiveDirectCreate', 'AssetReceiveDirectCreate1', 'CREATE')),
          new ModuleMenu('', '/Logistics/receive-direct', 'list', 'list', null, this.authService.hasPrivilege('AssetReceiveDirectList', 'AssetReceiveDirectList1', 'VIEW')),
          new ModuleMenu('', '/Logistics/receive-direct-report', 'list', 'Báo cáo', null, this.authService.hasPrivilege('AssetReceiveDirectList', 'AssetReceiveDirectList1', 'VIEW')),
        ]),

        new ModuleMenu('', '/Logistics/inventory-assets', 'list', 'InventoryAssets', null, this.authService.hasPrivilege('LogisticAssetInventory', 'LogisticAssetInventory1', 'VIEW')),
        new ModuleMenu('', '/Logistics/finished-good', 'list', 'Ước tính thành phẩm', null, this.authService.hasPrivilege('FinGoodEstimate', 'FinGoodEstimate1', 'VIEW')),
        new ModuleMenu('', '/Logistics/transfers', 'list', 'Transfer', null, this.authService.hasPrivilege('LogisticAssetTransfer', 'LogisticAssetTransfer1', 'VIEW')),
        new ModuleMenu('', '', 'move_to_inbox', 'Đổi mã thành phẩm', [
          new ModuleMenu('', '/Logistics/issue-defective', 'report', 'Mã xuất trừ', null, this.authService.hasPrivilege('AssetIssueDefectiveList', 'AssetIssueDefectiveList1', 'VIEW')),
          new ModuleMenu('', '/Logistics/receive-excess', 'inbox', 'Mã nhập thêm', null, this.authService.hasPrivilege('AssetReceiveExcessList', 'AssetReceiveExcessList1', 'VIEW')),
        ]),
        new ModuleMenu('', '', 'assignment', 'Kiểm kê', [
          new ModuleMenu('', '/Logistics/inventory-records-waiting-approval/waiting-approval', 'list', 'Phê duyệt', null),
          new ModuleMenu('', '/Logistics/inventory-records', 'list', 'list', null)
        ]),

        new ModuleMenu('', '', 'assignment', 'Báo phế', [
          new ModuleMenu('', '/Logistics/create-fin-goods-fail', 'add_circle', 'createNew', null, true),
          new ModuleMenu('', '/Logistics/fin-goods-fail-approval', 'list', 'Phê duyệt', null, true),
          new ModuleMenu('', '/Logistics/fin-goods-fail', 'list', 'list', null, true)
        ]),
        /*new ModuleMenu('', '', 'move_to_inbox', 'Kho trung chuyển', [
          new ModuleMenu('', '/Logistics/Exhibit/facilities', 'list', 'list', null, this.authService.hasPrivilege('LogisticAssetInventory', 'LogisticAssetInventory1', 'VIEW')),
          new ModuleMenu('', '/Logistics/Exhibit/inventory-assets', 'list', 'InventoryAssets', null, this.authService.hasPrivilege('LogisticAssetInventory', 'LogisticAssetInventory1', 'VIEW')),
          new ModuleMenu('', '/Logistics/Exhibit/receipt-transfer/list', 'format_indent_increase', 'Phiếu nhận', null, this.authService.hasPrivilege('IncomingShipment', 'IncomingShipment1', 'VIEW')),
          new ModuleMenu('', '/Logistics/Exhibit/export/list', 'format_indent_decrease', 'Phiếu xuất', null, this.authService.hasPrivilege('OutgoingShipment', 'OutgoingShipment1', 'VIEW')),
          new ModuleMenu('', '/Logistics/Exhibit/transfers/list', 'list', 'Transfer', null, this.authService.hasPrivilege('LogisticAssetTransfer', 'LogisticAssetTransfer1', 'VIEW')),
        ]),*/

        new ModuleMenu('', '/Logistics/inventory-histories', 'schedule', 'Báo cáo xuất nhập', null, this.authService.hasPrivilege('AssetChangeHistory', 'AssetChangeHistory1', 'VIEW')),

        new ModuleMenu('', '', 'move_to_inbox', 'Sản phẩm', [
          new ModuleMenu('', '/Logistics/products', 'add_circle', 'createNew', null, this.authService.hasPrivilege('LogisticProductList', 'LogisticProductList1', 'VIEW')),
          new ModuleMenu('', '/Logistics/categories', 'list', 'Quản lý Danh mục', null, this.authService.hasPrivilege('LogisticProductCategory', 'LogisticProductCategory1', 'VIEW')),
          new ModuleMenu('', '/Logistics/products-lock', 'list', 'Đã khóa', null, this.authService.hasPrivilege('LogisticProductCategory', 'LogisticProductCategory1', 'VIEW')),
        ]),
      ]),

      // ------------------- //

      new ModuleMenu('Manufacturing', '/Manufacturing/dashboard', 'dns', 'Manufacturing', [
        new ModuleMenu('', '/Manufacturing/create-production-run', 'add_circle', 'Tạo mới lệnh SX', null, this.authService.hasPrivilege('ProductionRunList', 'ProductionRunList1', 'CREATE')),
        new ModuleMenu('', '/Manufacturing/production-run', 'list', 'Danh sách lệnh SX', null, this.authService.hasPrivilege('ProductionRunList', 'ProductionRunList1', 'VIEW')),
        new ModuleMenu('', '/Manufacturing/production-run-report', 'list', 'Báo cáo sản lượng', null, this.authService.hasPrivilege('ProductionRunList', 'ProductionRunList1', 'VIEW')),
        new ModuleMenu('', '/Manufacturing/bom', 'table_chart', 'BOM', null, this.authService.hasPrivilege('BomList', 'BomList1', 'VIEW')),
      ]),

      new ModuleMenu('Financial', '/Financial/dashboard', 'attach_money', 'Finance', [

        new ModuleMenu('', '/Financial/dashboard', 'home', 'Dashboard', null,
          (this.authService.hasPrivilege('FinancialDashboard', 'FinancialDashboard1', 'VIEW')
            || this.authService.hasPrivilege('FinancialDashboard', 'FinancialDashboard2', 'VIEW')
            || this.authService.hasPrivilege('FinancialDashboard', 'FinancialDashboard3', 'VIEW')
            || this.authService.hasPrivilege('FinancialDashboard', 'FinancialDashboard4', 'VIEW')
            || this.authService.hasPrivilege('FinancialDashboard', 'FinancialDashboard5', 'VIEW')
            || this.authService.hasPrivilege('FinancialDashboard', 'FinancialDashboard6', 'VIEW')
            || this.authService.hasPrivilege('FinancialDashboard', 'FinancialDashboard7', 'VIEW')
            || this.authService.hasPrivilege('FinancialDashboard', 'FinancialDashboard8', 'VIEW')
          )),
        new ModuleMenu('', '', 'grain', 'Dữ liệu đầu kỳ', [
          new ModuleMenu('', '/Financial/beginning-inventory-assets', 'list', 'Tồn kho đầu kỳ', null, this.authService.hasPrivilege('AssetInventoryBeginning', 'AssetInventoryBeginning1', 'VIEW')),
          new ModuleMenu('', '/Financial/beginning-fixed-assets', 'list', 'TSCĐ đầu kỳ', null, this.authService.hasPrivilege('FixedAssetBeginning', 'FixedAssetBeginning1', 'VIEW')),
          new ModuleMenu('', '/Financial/beginning-supplier-liabilities', 'list', 'Nợ NCC đầu kỳ', null, this.authService.hasPrivilege('SupplierLiabilityBegin', 'SupplierLiabilityBegin', 'VIEW')),
          new ModuleMenu('', '/Financial/beginning-customer-liabilities', 'list', 'Nợ KH đầu kỳ', null, this.authService.hasPrivilege('CustomerLiabilityBegin', 'CustomerLiabilityBegin', 'VIEW')),
          new ModuleMenu('', '/Financial/beginning-gl-account', 'list', 'Số dư đầu kỳ', null, this.authService.hasPrivilege('GlAccountBegin', 'GlAccountBegin1', 'VIEW')),
          new ModuleMenu('', '/Financial/beginning-audit-log', 'list', 'Lịch sử thay đổi', null, this.authService.hasPrivilege('GlAccountBegin', 'GlAccountBegin1', 'VIEW')),
        ]),

        new ModuleMenu('', '', 'grain', 'Thu tiền hàng', [
          // new ModuleMenu('', '/Manufacturing/create-production-run', 'add_circle', 'createNew', null, this.authService.hasPrivilege('ProductionRunCreate', 'ProductionRunCreate1', 'CREATE')),
          new ModuleMenu('', '/Financial/create-customer-debts', 'list', 'Tạo phiếu thu', null, this.authService.hasPrivilege('CustomerLiability', 'CustomerLiability1', 'CREATE')),
          new ModuleMenu('', '/Financial/customer-debts', 'list', 'Công nợ khách hàng', null, this.authService.hasPrivilege('CustomerLiability', 'CustomerLiability1', 'VIEW')),
          new ModuleMenu('', '/Financial/customer-debts-payments', 'list', 'Danh sách phiếu thu', null, this.authService.hasPrivilege('CustomerLiability', 'CustomerLiability1', 'VIEW')),
          /* new ModuleMenu('', '/Financial/customer-other-invoices', 'list', 'Công nợ khác', null, this.authService.hasPrivilege('CustomerLiability', 'CustomerLiability1', 'VIEW')), */
          new ModuleMenu('', '', 'list', 'Công nợ khác', [
            new ModuleMenu('', '/Financial/create-customer-other-invoices', 'add_box', 'Tạo mới', null, true),
            new ModuleMenu('', '/Financial/appr-customer-other-invoice', 'add_box', 'Duyệt giảm CN', null, true),
            new ModuleMenu('', '/Financial/customer-other-invoices', 'add_box', 'DS công nợ khác', null, true),
          ]),
          new ModuleMenu('', '/Financial/customer-refund-payments', 'list', 'Hoàn thanh toán', null, this.authService.hasPrivilege('CustomerLiability', 'CustomerLiability1', 'VIEW')),
          /* new ModuleMenu('', '/Financial/customer-invoice-change-history', 'list', 'DS sửa công nợ', null, this.authService.hasPrivilege('CustomerLiabilityLog', 'CustomerLiabilityLog1', 'VIEW')), */
          new ModuleMenu('', '', 'list', 'Sửa công nợ', [
            new ModuleMenu('', '/Financial/customer-invoice-change-approve', 'add_box', 'Duyệt sửa', null, true),
            new ModuleMenu('', '/Financial/customer-invoice-change-history', 'add_box', 'DS sửa công nợ', null, true),
          ]),
        ]),
        new ModuleMenu('', '', 'grain', 'Thưởng doanh số', [
          new ModuleMenu('', '/Financial/commission-policy', 'list', 'Mức thưởng', null, this.authService.hasPrivilege('SalesCommissionPolicy', 'SalesCommissionPolicy1', 'VIEW')),
          new ModuleMenu('', '/Financial/sale-employees', 'list', 'NV Sale', null, this.authService.hasPrivilege('SalesEmployee', 'SalesEmployee1', 'VIEW')),
          new ModuleMenu('', '/Financial/commission-classify', 'list', 'Hạn mức công nợ', null, this.authService.hasPrivilege('SalesCommissionClassify', 'SalesCommissionClassify1', 'VIEW')),
          new ModuleMenu('', '/Financial/commission-review', 'list', 'Chi thưởng doanh số', null, this.authService.hasPrivilege('SalesCommissionReview', 'SalesCommissionReview1', 'VIEW')),
        ]),

        new ModuleMenu('Financial', '/Financial/dashboard', 'extension', 'DCHN', [
          new ModuleMenu('', '', 'grain', 'Thu khác', [
            new ModuleMenu('', '/Financial/create-other-invoices-dchn', 'radio_button_checked', 'Tạo phiếu thu', null, this.authService.hasPrivilege('IncomeOtherInvoiceDchn', 'IncomeOtherInvoiceDchn1', 'CREATE'), ['DCHN', 'Thu khác']),
            new ModuleMenu('', '/Financial/other-invoices-dchn', 'radio_button_checked', 'Danh sách phiếu thu', null, this.authService.hasPrivilege('IncomeOtherInvoiceDchn', 'IncomeOtherInvoiceDchn1', 'VIEW'), ['DCHN', 'Thu khác']),
            new ModuleMenu('', '/Financial/other-invoices-liabilities-dchn', 'radio_button_checked', 'Danh sách công nợ', null, this.authService.hasPrivilege('IncomeOtherInvoiceLiabilitiesDchn', 'IncomeOtherInvoiceLiabilitiesDchn1', 'VIEW'), ['DCHN', 'Thu khác']),
          ]),

          new ModuleMenu('', '', 'grain', 'Chi NCC', [
            new ModuleMenu('', '/Financial/outgoing-payments-nccd-dchn', 'radio_button_checked', 'Chi NCC trong nước', null, this.authService.hasPrivilege('OutPaymentDomesticSuppDchn', 'OutPaymentDomesticSuppDchn1', 'VIEW'), ['DCHN', 'Chi NCC']),
            new ModuleMenu('', '/Financial/outgoing-payments-nccf-dchn', 'radio_button_checked', 'Chi NCC nước ngoài', null, this.authService.hasPrivilege('OutPaymentForeignSuppDchn', 'OutPaymentForeignSuppDchn1', 'VIEW'), ['DCHN', 'Chi NCC']),
            new ModuleMenu('', '/Financial/supplier-debts-dchn', 'radio_button_checked', 'Công nợ NCC', null, this.authService.hasPrivilege('SupplierLiabilitiesDchn', 'SupplierLiabilitiesDchn1', 'VIEW'), ['DCHN', 'Chi NCC']),
            /* new ModuleMenu('', '/Financial/supplier-other-debts-dchn', 'radio_button_checked', 'Công nợ khác', null, this.authService.hasPrivilege('SupplierLiabilitiesDchn', 'SupplierLiabilitiesDchn1', 'VIEW')), */
            new ModuleMenu('', '', 'radio_button_checked', 'Công nợ khác', [
              new ModuleMenu('', '/Financial/create-sup-other-inv-dchn', 'add_box', 'Tạo mới', null, true, ['DCHN', 'Công nợ khác']),
              new ModuleMenu('', '/Financial/appr-sup-other-inv-dchn', 'add_box', 'Duyệt tăng CN', null, true, ['DCHN', 'Công nợ khác']),
              new ModuleMenu('', '/Financial/supplier-other-inv-dchn', 'add_box', 'DS công nợ khác', null, true, ['DCHN', 'Công nợ khác']),
            ]),
            /* new ModuleMenu('', '/Financial/supplier-invoice-change-history-dchn', 'radio_button_checked', 'DS sửa công nợ', null, this.authService.hasPrivilege('SupplierLiabilitiesLogDchn', 'SupplierLiabilitiesLogDchn1', 'VIEW')), */
            new ModuleMenu('', '', 'radio_button_checked', 'Sửa công nợ', [
              new ModuleMenu('', '/Financial/appr-supplier-invoice-change-dchn', 'add_box', 'Duyệt sửa', null, true, ['DCHN', 'Sửa công nợ']),
              new ModuleMenu('', '/Financial/supplier-invoice-change-history-dchn', 'add_box', 'DS sửa công nợ', null, true, ['DCHN', 'Sửa công nợ']),
            ]),
          ]),

          new ModuleMenu('', '', 'grain', 'Chi khác', [
            new ModuleMenu('', '/Financial/create-outgoing-payments-dchn', 'radio_button_checked', 'Tạo mới', null, this.authService.hasPrivilege('OutPaymentOperateExpenseCreateDchn', 'OutPaymentOperateExpenseCreateDchn1', 'CREATE'), ['DCHN', 'Chi khác']),
            new ModuleMenu('', '/Financial/outgoing-payments-gt-dchn', 'radio_button_checked', 'Danh sách', null, this.authService.hasPrivilege('OutPaymentOperateExpenseSuppDchn', 'OutPaymentOperateExpenseSuppDchn1', 'VIEW'), ['DCHN', 'Chi khác']),
            // new ModuleMenu('', '/Financial/outgoing-payments-tscd-dchn', 'radio_button_checked', 'Mua TSCĐ', null, this.authService.hasPrivilege('OutPaymentFixedAsstPurchaseDchn', 'OutPaymentFixedAsstPurchaseDchn1', 'VIEW')),
          ]),

          new ModuleMenu('', '/Financial/outgoing-payments-d-dchn', 'grain', 'Duyệt chi', null, this.authService.hasPrivilege('OutPaymentWaitApprDchn', 'OutPaymentWaitApprDchn1', 'VIEW'), ['DCHN']),
          new ModuleMenu('', '/Financial/outgoing-payments-tt-dchn', 'grain', 'Xác nhận chi', null, this.authService.hasPrivilege('OutPaymentWaitDeliveryDchn', 'OutPaymentWaitDeliveryDchn1', 'VIEW'), ['DCHN']),

          new ModuleMenu('', '/Financial/invested-capital-dchn', 'grain', 'Huy động vốn', null, this.authService.hasPrivilege('IncomePaymentInvestCapitalSaleDchn', 'IncomePaymentInvestCapitalSaleDchn1', 'VIEW'), ['DCHN']),
          new ModuleMenu('', '/Financial/loan-agreements-dchn', 'grain', 'Theo dõi vay', null, this.authService.hasPrivilege('LoanAgreementDchn', 'LoanAgreementDchn1', 'VIEW'), ['DCHN']),

          /*new ModuleMenu('', '', 'grain', 'Theo dõi vay', [
            new ModuleMenu('', '/Financial/report-loan-agreements-dchn', 'radio_button_checked', 'Báo cáo chi tiết', null, this.authService.hasPrivilege('LoanAgreementReportDchn', 'LoanAgreementReportDchn1', 'VIEW')),
          ]),*/
          new ModuleMenu('', '/Financial/shareholders-dchn', 'grain', 'Cổ đông', null, this.authService.hasPrivilege('ShareholderDchn', 'ShareholderDchn1', 'VIEW'), ['DCHN']),
          new ModuleMenu('', '/Financial/dividend-allocations-dchn', 'grain', 'Hoàn vốn cổ đông', null, this.authService.hasPrivilege('DividendAllocationsDchn', 'DividendAllocationsDchn1', 'VIEW'), ['DCHN']),
          new ModuleMenu('', '', 'grain', 'FixedAsset', [
            new ModuleMenu('', '/Financial/fixed-assets-list-dchn', 'radio_button_checked', 'Danh sách', null, this.authService.hasPrivilege('FixedAssetDchn', 'FixedAssetDchn1', 'VIEW'), ['DCHN', 'FixedAsset']),
            new ModuleMenu('', '/Financial/fixed-assets-dchn', 'radio_button_checked', 'Mua TSCĐ', null, this.authService.hasPrivilege('FixedAssetDchn', 'FixedAssetDchn1', 'VIEW'), ['DCHN', 'FixedAsset']),
            new ModuleMenu('', '/Financial/fixed-assets-sell-dchn', 'radio_button_checked', 'Bán TSCĐ', null, this.authService.hasPrivilege('FixedAssetDchn', 'FixedAssetDchn1', 'VIEW'), ['DCHN', 'FixedAsset']),
            new ModuleMenu('', '/Financial/fixed-assets-liabilities-dchn', 'radio_button_checked', 'Công nợ TSCĐ', null, this.authService.hasPrivilege('FixedAssetDchn', 'FixedAssetDchn1', 'VIEW'), ['DCHN', 'FixedAsset']),
            new ModuleMenu('', '/Financial/asset-valuation-dchn', 'radio_button_checked', 'Định giá TSCĐ', null, this.authService.hasPrivilege('AssetValuationDchn', 'AssetValuationDchn1', 'VIEW'), ['DCHN', 'FixedAsset']),
          ]),
          new ModuleMenu('', '/Financial/deposits-withdrawals-dchn', 'account_balance_wallet', 'Chuyển tiền nội bộ', null, this.authService.hasPrivilege('DepositWithdrawalDchn', 'DepositWithdrawalDchn1', 'VIEW'), ['DCHN']),
          new ModuleMenu('', '/Financial/advances-dchn', 'grain', 'Tạm ứng', null, this.authService.hasPrivilege('AdvanceEmployeeDchn', 'AdvanceEmployeeDchn1', 'VIEW'), ['DCHN']),
          // new ModuleMenu('', '/Financial/fixed-assets-dchn', 'grain', 'FixedAsset', null, this.authService.hasPrivilege('FixedAssetDchn', 'FixedAssetDchn1', 'VIEW')),
          new ModuleMenu('', '/Financial/cashbooks-dchn', 'grain', 'Quỹ tiền mặt', null, this.authService.hasPrivilege('CashbooksDchn', 'CashbooksDchn1', 'VIEW'), ['DCHN']),
          // new ModuleMenu('', '/Financial/profit-accounting-dchn', 'grain', 'Hạch toán', null, this.authService.hasPrivilege('AccountProfitDchn', 'AccountProfitDchn1', 'VIEW')),
          new ModuleMenu('', '/Financial/profit-detail-dchn', 'grain', 'Hạch toán', null, true, ['DCHN']),
          new ModuleMenu('', '/Financial/asset-report-dchn', 'grain', 'Đối soát tài sản', null, this.authService.hasPrivilege('AssetReportDchn', 'AssetReportDchn1', 'VIEW'), ['DCHN']),
          new ModuleMenu('', '/Financial/receipts-report-dchn', 'grain', 'Tổng hợp phiếu thu', null, this.authService.hasPrivilege('ReceiptsReportDchn', 'ReceiptsReportDchn1', 'VIEW'), ['DCHN']),
          new ModuleMenu('', '/Financial/payments-report-dchn', 'grain', 'Tổng hợp phiếu chi', null, this.authService.hasPrivilege('PaymentsReportDchn', 'PaymentsReportDchn1', 'VIEW'), ['DCHN']),
        ]),

        new ModuleMenu('', '', 'extension', 'FSN', [

          new ModuleMenu('', '', 'grain', 'Thu khác', [
            new ModuleMenu('', '/Financial/create-other-invoices-fsn', 'radio_button_checked', 'Tạo phiếu thu', null, this.authService.hasPrivilege('IncomeOtherInvoiceFsn', 'IncomeOtherInvoiceFsn1', 'CREATE'), ['FSN', 'Thu khác']),
            new ModuleMenu('', '/Financial/other-invoices-fsn', 'radio_button_checked', 'Danh sách phiếu thu', null, this.authService.hasPrivilege('IncomeOtherInvoiceFsn', 'IncomeOtherInvoiceFsn1', 'VIEW'), ['FSN', 'Thu khác']),
            new ModuleMenu('', '/Financial/other-invoices-liabilities-fsn', 'radio_button_checked', 'Danh sách công nợ', null, this.authService.hasPrivilege('IncomeOtherInvoiceLiabilitiesFsn', 'IncomeOtherInvoiceLiabilitiesFsn1', 'VIEW'), ['FSN', 'Thu khác']),
          ]),

          new ModuleMenu('', '', 'grain', 'Chi NCC', [
            new ModuleMenu('', '/Financial/outgoing-payments-nccd-fsn', 'radio_button_checked', 'Chi NCC trong nước', null, this.authService.hasPrivilege('OutPaymentDomesticSuppFsn', 'OutPaymentDomesticSuppFsn1', 'VIEW'), ['FSN', 'Chi NCC']),
            new ModuleMenu('', '/Financial/outgoing-payments-nccf-fsn', 'radio_button_checked', 'Chi NCC nước ngoài', null, this.authService.hasPrivilege('OutPaymentForeignSuppFsn', 'OutPaymentForeignSuppFsn1', 'VIEW'), ['FSN', 'Chi NCC']),
            new ModuleMenu('', '/Financial/supplier-debts-fsn', 'radio_button_checked', 'Công nợ NCC', null, this.authService.hasPrivilege('SupplierLiabilitiesFsn', 'SupplierLiabilitiesFsn1', 'VIEW'), ['FSN', 'Chi NCC']),
            /* new ModuleMenu('', '/Financial/supplier-other-debts-fsn', 'radio_button_checked', 'Công nợ khác', null, this.authService.hasPrivilege('SupplierLiabilitiesFsn', 'SupplierLiabilitiesFsn1', 'VIEW')), */
            new ModuleMenu('', '', 'radio_button_checked', 'Công nợ khác',[
              new ModuleMenu('', '/Financial/create-sup-other-inv-fsn', 'add_box', 'Tạo mới', null, true, ['FSN', 'Công nợ khác']),
              new ModuleMenu('', '/Financial/appr-sup-other-inv-fsn', 'add_box', 'Duyệt tăng CN', null, true, ['FSN', 'Công nợ khác']),
              new ModuleMenu('', '/Financial/supplier-other-inv-fsn', 'add_box', 'DS công nợ khác', null, true, ['FSN', 'Công nợ khác']),
            ]),
            /* new ModuleMenu('', '/Financial/supplier-invoice-change-history-fsn', 'radio_button_checked', 'DS sửa công nợ', null, this.authService.hasPrivilege('SupplierLiabilitiesLogFsn', 'SupplierLiabilitiesLogFsn1', 'VIEW')), */
            new ModuleMenu('', '', 'radio_button_checked', 'Sửa công nợ', [
              new ModuleMenu('', '/Financial/appr-supplier-invoice-change-fsn', 'add_box', 'Duyệt sửa', null, true, ['FSN', 'Sửa công nợ']),
              new ModuleMenu('', '/Financial/supplier-invoice-change-history-fsn', 'add_box', 'DS sửa công nợ', null, true, ['FSN', 'Sửa công nợ']),
            ]),
          ]),

          new ModuleMenu('', '', 'grain', 'Chi khác', [
            new ModuleMenu('', '/Financial/create-outgoing-payments-fsn', 'radio_button_checked', 'Tạo mới', null, this.authService.hasPrivilege('OutPaymentOperateExpenseCreateFsn', 'OutPaymentOperateExpenseCreateFsn1', 'CREATE'), ['FSN', 'Sửa công nợ']),
            new ModuleMenu('', '/Financial/outgoing-payments-gt-fsn', 'radio_button_checked', 'Danh sách', null, this.authService.hasPrivilege('OutPaymentOperateExpenseSuppFsn', 'OutPaymentOperateExpenseSuppFsn1', 'VIEW'), ['FSN', 'Sửa công nợ']),
            // new ModuleMenu('', '/Financial/outgoing-payments-tscd-dchn', 'radio_button_checked', 'Mua TSCĐ', null, this.authService.hasPrivilege('OutPaymentFixedAsstPurchaseDchn', 'OutPaymentFixedAsstPurchaseDchn1', 'VIEW')),
          ]),

          new ModuleMenu('', '/Financial/outgoing-payments-d-fsn', 'grain', 'Duyệt chi', null, this.authService.hasPrivilege('OutPaymentWaitApprFsn', 'OutPaymentWaitApprFsn1', 'VIEW'), ['FSN']),
          new ModuleMenu('', '/Financial/outgoing-payments-tt-fsn', 'grain', 'Xác nhận chi', null, this.authService.hasPrivilege('OutPaymentWaitDeliveryFsn', 'OutPaymentWaitDeliveryFsn1', 'VIEW'), ['FSN']),

          new ModuleMenu('', '/Financial/invested-capital-fsn', 'grain', 'Huy động vốn', null, this.authService.hasPrivilege('IncomePaymentInvestCapitalSaleFsn', 'IncomePaymentInvestCapitalSaleFsn1', 'VIEW'), ['FSN']),
          new ModuleMenu('', '/Financial/loan-agreements-fsn', 'radio_button_checked', 'Theo dõi vay', null, this.authService.hasPrivilege('LoanAgreementFsn', 'LoanAgreementFsn1', 'VIEW'), ['FSN']),

          /*new ModuleMenu('', '', 'grain', 'Theo dõi vay', [
            new ModuleMenu('', '/Financial/report-loan-agreements-fsn', 'radio_button_checked', 'Báo cáo chi tiết', null, this.authService.hasPrivilege('LoanAgreementReportFsn', 'LoanAgreementReportFsn1', 'VIEW')),
          ]),*/
          new ModuleMenu('', '/Financial/shareholders-fsn', 'grain', 'Cổ đông', null, this.authService.hasPrivilege('ShareholderFsn', 'ShareholderFsn1', 'VIEW'), ['FSN']),
          new ModuleMenu('', '/Financial/dividend-allocations-fsn', 'grain', 'Hoàn vốn cổ đông', null, this.authService.hasPrivilege('DividendAllocationsFsn', 'DividendAllocationsFsn1', 'VIEW'), ['FSN']),
          new ModuleMenu('', '', 'grain', 'FixedAsset', [
            new ModuleMenu('', '/Financial/fixed-assets-list-fsn', 'radio_button_checked', 'Danh sách', null, this.authService.hasPrivilege('FixedAssetFsn', 'FixedAssetFsn1', 'VIEW'), ['FSN', 'FixedAsset']),
            new ModuleMenu('', '/Financial/fixed-assets-fsn', 'radio_button_checked', 'Mua TSCĐ', null, this.authService.hasPrivilege('FixedAssetFsn', 'FixedAssetFsn1', 'VIEW'), ['FSN', 'FixedAsset']),
            new ModuleMenu('', '/Financial/fixed-assets-sell-fsn', 'radio_button_checked', 'Bán TSCĐ', null, this.authService.hasPrivilege('FixedAssetFsn', 'FixedAssetFsn1', 'VIEW'), ['FSN', 'FixedAsset']),
            new ModuleMenu('', '/Financial/fixed-assets-liabilities-fsn', 'radio_button_checked', 'Công nợ TSCĐ', null, this.authService.hasPrivilege('FixedAssetFsn', 'FixedAssetFsn1', 'VIEW'), ['FSN', 'FixedAsset']),
            new ModuleMenu('', '/Financial/asset-valuation-fsn', 'radio_button_checked', 'Định giá TSCĐ', null, this.authService.hasPrivilege('AssetValuationFsn', 'AssetValuationFsn1', 'VIEW'), ['FSN', 'FixedAsset']),
          ]),
          new ModuleMenu('', '/Financial/deposits-withdrawals-fsn', 'account_balance_wallet', 'Chuyển tiền nội bộ', null, this.authService.hasPrivilege('DepositWithdrawalFsn', 'DepositWithdrawalFsn1', 'VIEW'), ['FSN']),
          new ModuleMenu('', '/Financial/advances-fsn', 'grain', 'Tạm ứng', null, this.authService.hasPrivilege('AdvanceEmployeeFsn', 'AdvanceEmployeeFsn1', 'VIEW'), ['FSN']),
          // new ModuleMenu('', '/Financial/fixed-assets-fsn', 'grain', 'FixedAsset', null, this.authService.hasPrivilege('FixedAssetFsn', 'FixedAssetFsn1', 'VIEW')),
          new ModuleMenu('', '/Financial/cashbooks-fsn', 'grain', 'Quỹ tiền mặt', null, this.authService.hasPrivilege('CashbooksFsn', 'CashbooksFsn1', 'VIEW'), ['FSN']),
          // new ModuleMenu('', '/Financial/profit-accounting-fsn', 'grain', 'Hạch toán', null, this.authService.hasPrivilege('AccountProfitFsn', 'AccountProfitFsn1', 'VIEW')),
          new ModuleMenu('', '/Financial/profit-detail-fsn', 'grain', 'Hạch toán', null, true, ['FSN']),
          new ModuleMenu('', '/Financial/asset-report-fsn', 'grain', 'Đối soát tài sản', null, this.authService.hasPrivilege('AssetReportFsn', 'AssetReportFsn1', 'VIEW'), ['FSN']),
          new ModuleMenu('', '/Financial/receipts-report-fsn', 'grain', 'Tổng hợp phiếu thu', null, this.authService.hasPrivilege('ReceiptsReportFsn', 'ReceiptsReportFsn1', 'VIEW'), ['FSN']),
          new ModuleMenu('', '/Financial/payments-report-fsn', 'grain', 'Tổng hợp phiếu chi', null, this.authService.hasPrivilege('PaymentsReportFsn', 'PaymentsReportFsn1', 'VIEW'), ['FSN']),
        ]),

        new ModuleMenu('', '', 'euro_symbol', 'Giá thành', [
          new ModuleMenu('', '/Financial/currency-exchange', 'euro_symbol', 'Giá nguyên tệ', null, this.authService.hasPrivilege('CostExchangeCurrency', 'CostExchangeCurrency1', 'VIEW')),
          new ModuleMenu('', '/Financial/import-expense', 'euro_symbol', 'Phí nhập khẩu', null, this.authService.hasPrivilege('CostImportExpense', 'CostImportExpense1', 'VIEW')),
          new ModuleMenu('', '/Financial/products', 'euro_symbol', 'Giá sản phẩm', null, this.authService.hasPrivilege('CostComponentProduct', 'CostComponentProduct1', 'VIEW')),
        ]),
        // new ModuleMenu('', '', 'assignment', 'Công nợ trả lại', [
        //   new ModuleMenu('', '/Financial/liabilities-suppliers', 'grain', 'DCHN', null),
        //   new ModuleMenu('', '/Financial/liabilities-suppliers-fsn', 'grain', 'FSN', null)
        // ]),
        new ModuleMenu('', '/Financial/exchange-currency', 'euro_symbol', 'Tỷ giá', null, this.authService.hasPrivilege('ExchangeCurrency', 'ExchangeCurrency1', 'VIEW')),
      ]),

      // ------------------- //

      new ModuleMenu('Admin', '/Admin/dashboard', 'star', 'Admin', [
        // new ModuleMenu('', '/Admin/dashboard', 'home', 'Dashboard'),
        new ModuleMenu('', '/Admin/users', 'terrain', 'Quản lý người dùng', null, this.authService.hasPrivilege('UserList', 'UserList1', 'SPECIAL-PERMS')),
      ])
    ];

    this.refreshMenu();
  }

  refreshMenu() {
    this.modules.forEach((module) => {
      module.status = this.getStatus(module);
    });
  }

  getStatus(module): boolean {
    let status = false;
    if (module.children && module.children.length) {
      module.children.forEach((child) => {
        child.status = this.getStatus(child);
        if (child.status) {
          status = true;
        }
      });
    } else {
      status = module.status;
    }
    return status;
  }

  setModule(next: ActivatedRouteSnapshot) {
    this.initMenu();
    let path = next.routeConfig.path;
    if (!path) {
      path = 'Home';
    }
    this.modules.forEach((module) => {
      if (module.id === path) {
        this.module = module;
        this.leftSidebarService.setModule(this.module);
        this.breadcrumbService.setModule(this.module);
      }
    });
  }

  setMenu(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let url = state.url;
    let isAction = true;
    this.breadcrumbService.setAction(null);
    let moduleQueue = new Array<ModuleMenu>();
    moduleQueue.push(this.module);
    while (moduleQueue.length > 0) {
      const m = moduleQueue.shift();
      if (m.children) {
        m.children.forEach(childModule => {
          moduleQueue.push(childModule);
        });
      }
      if (m.routerLink === url) {
        this.breadcrumbService.setMenu(m);
        isAction = false;
      }
    }// end while

    /* if (this.module && this.module.children) {
      this.module.children.forEach((module) => {
        if (module.routerLink === url) {
          this.breadcrumbService.setMenu(module);
          isAction = false;
        } else {
          if (module && module.children) {
            module.children.forEach((child) => {
              if (child.routerLink === url) {
                this.breadcrumbService.setMenu(module);
                this.breadcrumbService.setAction(child.title);
                isAction = false;
              }
            });
          }
        }
      });
    } */
    if (isAction) {
      const path = next.routeConfig.path;
      if (path === 'detail/:id') {
        this.breadcrumbService.setAction(this.translateService.get('Detail') + ' #' + next.params.id);
        url = url.split('/detail/')[0];
      } else if (path === 'user_detail/:id') {
        this.breadcrumbService.setAction('Detail');
        url = url.split('/create')[0];
      } else if (path === 'create') {
        this.breadcrumbService.setAction('Create');
        url = url.split('/create')[0];
      } else if (path === 'update/:id') {
        this.breadcrumbService.setAction(this.translateService.get('Update') + ' #' + next.params.id);
        url = url.split('/update/')[0];
      } else if (path === 'detail/:id/billing-detail/:pId') {
        this.breadcrumbService.setAction(this.translateService.get('Detail') + ' #' + next.params.id + ' / ' + this.translateService.get('Employee') + ' #' + next.params.pId);
        url = url.split('/detail/')[0];
      }
      moduleQueue = new Array<ModuleMenu>();
      moduleQueue.push(this.module);
      while(moduleQueue.length > 0){
        let m = moduleQueue.shift();
        if (m.routerLink === url) {
          this.breadcrumbService.setMenu(m);
        }
        if(m.children){
          m.children.forEach(childModule => {
            moduleQueue.push(childModule);
          });
        }
      }//end while
      /* if (this.module && this.module.children) {
        this.module.children.forEach((module) => {
          if (module.routerLink === url) {
            this.breadcrumbService.setMenu(module);
          } else {
            if (module && module.children) {
              module.children.forEach((child) => {
                if (child.routerLink === url) {
                  this.breadcrumbService.setMenu(child);
                }
              });
            }
          }
        });
      } */
    }
  }
}
