import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {
  }

  setAuthorization(moquiSessionToken) {
    if (moquiSessionToken) {
      localStorage.setItem('moquiSessionToken', moquiSessionToken);
    }
  }

  getAuthorization() {
    return localStorage.getItem('moquiSessionToken');
  }

  removeAuthorization() {
    localStorage.removeItem('moquiSessionToken');
  }

  setPrivilege() {
    return new Promise((resolve) => {
      localStorage.setItem('key', '');
      this.http.get(`dchn/user-account/grant-privilege`).subscribe((response: any) => {
        if (response && response.listData) {
          localStorage.setItem('key', JSON.stringify(response.listData));
        }
        resolve();
      });
    });
  }

  removePrivilege() {
    localStorage.removeItem('key');
  }

  getPrivilege(): any {
    let privilege = null;
    try {
      privilege = JSON.parse(localStorage.getItem('key'));
    } catch (e) {
    }
    return privilege;
  }

  hasPrivilege(screenId: string, artifactId: string, permission: string): boolean {
    let has = false;
    const permissions = this.getPrivilege();
    if (permissions && permissions.length > 0 && screenId && artifactId && permission) {
      for (const p of permissions) {
        if (p && p.screenId === screenId && p.artifacts && p.artifacts.length > 0) {
          for (const artifact of p.artifacts) {
            if (artifact && artifact.artifactId === artifactId && artifact.permissions && artifact.permissions.length > 0) {
              if (artifact.permissions.includes('ALL')) {
                has = true;
              } else {
                has = artifact.permissions.includes(permission);
              }
              break;
            }
          }
          break;
        }
      }
    }
    return has;

  }
}
