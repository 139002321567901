import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ModuleMenuModel as ModuleMenu} from '../../shared/module-menu/module-menu.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private readonly module: BehaviorSubject<ModuleMenu>;
  private readonly menu: BehaviorSubject<ModuleMenu>;
  private readonly action: BehaviorSubject<string>;
  private readonly linkDownload: BehaviorSubject<string>;
  private readonly routerLinkBack: BehaviorSubject<string>;
  private readonly routerLinkCreate: BehaviorSubject<string>;

  constructor() {
    this.module = new BehaviorSubject<ModuleMenu>(new ModuleMenu());
    this.menu = new BehaviorSubject<ModuleMenu>(new ModuleMenu());
    this.action = new BehaviorSubject<string>(null);
    this.linkDownload = new BehaviorSubject<string>(null);
    this.routerLinkBack = new BehaviorSubject<string>(null);
    this.routerLinkCreate = new BehaviorSubject<string>(null);
  }

  getModule(): BehaviorSubject<ModuleMenu> {
    return this.module;
  }

  setModule(module: ModuleMenu) {
    this.module.next(module);
  }

  getMenu(): BehaviorSubject<ModuleMenu> {
    return this.menu;
  }

  setMenu(menu: ModuleMenu) {
    this.menu.next(menu);
  }

  getAction(): BehaviorSubject<string> {
    return this.action;
  }

  setAction(action: string) {
    this.action.next(action);
  }

  getLinkDownload(): BehaviorSubject<string> {
    return this.linkDownload;
  }

  setLinkDownload(linkDownload: string) {
    this.linkDownload.next(linkDownload);
  }

  getRouterLinkBack(): BehaviorSubject<string> {
    return this.routerLinkBack;
  }

  setRouterLinkBack(routerLinkBack: string) {
    this.routerLinkBack.next(routerLinkBack);
  }

  getRouterLinkCreate(): BehaviorSubject<string> {
    return this.routerLinkCreate;
  }

  setRouterLinkCreate(routerLinkCreate: string) {
    this.routerLinkCreate.next(routerLinkCreate);
  }
}
