import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {AuthService} from '../service/auth.service';
import {UserService} from '../service/user.service';
import {ProgressService} from '../../layout/progress/progress.service';
import {AlertService} from '../service/alert.service';
import {LinksService} from '../service/links.service';

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {

  error: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private linksService: LinksService,
    private progressService: ProgressService,
    private alertService: AlertService
  ) {
    this.error = new Audio();
    this.error.src = `../..${environment.baseHref}assets/audio/dont-think-so.mp3`;
    this.error.load();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressService.setProgress(true);
    const request = req.clone({
      url: req.url.includes('http') ? req.url : `${environment.baseURL}/${req.url}`,
      // withCredentials: true
    });
    request.headers.append('Accept', 'application/json');
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type) {
          this.progressService.setProgress(false);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.error.play();
        this.progressService.setProgress(false);
        this.errorHandle(error);
        return throwError(error);
      })
    );
  }

  errorHandle(error: HttpErrorResponse) {
    const statusCode = error.status;
    if (statusCode === 400) {
      let message = 'ServiceUnavailable';
      if (error.error && error.error.errors) {
        message = error.error.errors;
      }
      this.alertService.onError(message).then();
    } else if (statusCode === 401) {
      UserService.setRouterState(this.router.routerState.snapshot.url);
      this.authService.removeAuthorization();
      this.linksService.removeLinks();
      this.alertService.onError('Unauthorized').then(() => {
        this.router.navigateByUrl('/login').then();
      });
    } else if (statusCode === 403) {
      this.alertService.onError('Forbidden').then(() => {
        // this.router.navigateByUrl('/login').then();
      });
    } else if (statusCode === 404) {
      this.alertService.onError('NotFound').then();
    } else {
      this.alertService.onError().then();
    }
  }
}
