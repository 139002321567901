import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MessagesModule, TooltipModule} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {KeyFilterModule} from 'primeng/keyfilter';
import {OverlayPanelModule} from 'primeng/overlaypanel';

import {TranslatePipe} from './translate/translate.pipe';

import {TableFilterComponent} from './components/table-filter/table-filter.component';

@NgModule({
  declarations: [
    TranslatePipe,
    TableFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ScrollPanelModule,
    AutoCompleteModule,
    SelectButtonModule,
    ButtonModule,
    DialogModule,
    KeyFilterModule,
    OverlayPanelModule,
    MessagesModule,
    TooltipModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ScrollPanelModule,
    AutoCompleteModule,
    SelectButtonModule,
    ButtonModule,
    DialogModule,
    KeyFilterModule,
    OverlayPanelModule,
    TranslatePipe,
    TableFilterComponent,
  ]
})
export class SharedModule {
}
