import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ModuleMenuModel as ModuleMenu} from '../../shared/module-menu/module-menu.model';

@Injectable({
  providedIn: 'root'
})
export class LeftSidebarService {

  private readonly module: BehaviorSubject<ModuleMenu>;

  constructor() {
    this.module = new BehaviorSubject<ModuleMenu>(new ModuleMenu());
  }

  getModule(): BehaviorSubject<ModuleMenu> {
    return this.module;
  }

  setModule(module: ModuleMenu) {
    this.module.next(module);
  }
}
