import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {ModuleMenuModel as ModuleMenu} from '../../shared/module-menu/module-menu.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private readonly modules: BehaviorSubject<ModuleMenu[]>;

  constructor() {
    this.modules = new BehaviorSubject<ModuleMenu[]>([]);
  }

  getModules(): BehaviorSubject<ModuleMenu[]> {
    return this.modules;
  }

  setModules(modules: ModuleMenu[]) {
    this.modules.next(modules);
  }
}
