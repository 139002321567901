import {Component, Input, OnInit} from '@angular/core';

import {Table} from 'primeng/table';
import {Col} from '../col.model';
import {TreeTable} from 'primeng/primeng';

@Component({
  selector: 'tr[app-table-filter]',
  templateUrl: './table-filter.component.html'
})
export class TableFilterComponent implements OnInit {

  @Input() table: Table;
  @Input() treeTable: TreeTable;
  @Input() columns: Array<Col>;
  @Input() tableCheckbox: boolean;
  @Input() expandColumn: boolean;
  @Input() expandColumn1: boolean;
  @Input() actionColumn: boolean;
  @Input() actionColumn2: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
