import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {AuthService} from '../../shared/service/auth.service';
import {UserService} from '../../shared/service/user.service';
import {LinksService} from '../../shared/service/links.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  options;
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  login(item: any, options): Observable<any> {
    return this.http.post('dchn/login', item , options)
      .pipe(tap(response => {
        if (response) {
          UserService.setUsername(item.username);
        }
      }));
  }

  changePassword(item: any, options): Observable<any> {
    return this.http.post('dchn/change-password', item, options);
  }

  logout(options): Observable<any> {
    return this.http.post('dchn/logout', null, options)
      .pipe(tap(response => {
        if (response) {
          this.authService.removeAuthorization();
          this.authService.removePrivilege();
        }
      }));
  }
}
