import {Injectable} from '@angular/core';

import Swal from 'sweetalert2';

import {environment} from '../../../environments/environment';
import {TranslateService} from '../translate/translate.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  success: any;
  error: any;

  constructor(private translateService: TranslateService) {
    this.success = new Audio();
    this.success.src = `../..${environment.baseHref}assets/audio/coins.mp3`;
    this.success.load();
    this.error = new Audio();
    this.error.src = `../..${environment.baseHref}assets/audio/dont-think-so.mp3`;
    this.error.load();
  }

  onSuccess(title?: string) {
    this.success.play();
    return Swal.fire({
      type: 'success',
      title: title ? this.translateService.get(title) : this.translateService.get('Success'),
      showConfirmButton: false,
      timer: 1000
    });
  }

  onMessage(title?: string, text?: string) {
    this.success.play();
    return Swal.fire({
      type: 'success',
      title: title ? this.translateService.get(title) : this.translateService.get('Success'),
      text: text ? this.translateService.get(text) : null,
      confirmButtonText: this.translateService.get('Close')
    });
  }

  onWarning(title?: string, text?: string) {
    this.error.play();
    return Swal.fire({
      type: 'warning',
      title: title ? this.translateService.get(title) : this.translateService.get('Warning'),
      text: text ? this.translateService.get(text) : null,
      confirmButtonText: this.translateService.get('Close')
    });
  }

  onError(text?: string) {
    this.error.play();
    return Swal.fire({
      type: 'error',
      title: this.translateService.get('Oops'),
      text: text ? this.translateService.get(text) : this.translateService.get('ServiceUnavailable'),
      confirmButtonText: this.translateService.get('Retry')
    });
  }

}
